import React from 'react'
import { Link } from 'react-router-dom';
import avideo from './videos/Digital Marketing Website Background Video.mp4'
import './About.css'
import image from './asset/testimonials1.jpg'
import devu from './asset/devu1.jpeg'
import dip from './asset/IMG_20240807_160454.jpg'

function About() {
  const teamMembers = [
    { name: 'Devayani Pawar',imgSrc:devu, designation: 'Web Developer', delay: '0.3s',flink:'https://wa.link/7hlmyv',ilink:'https://www.instagram.com/devu_pawar15/',llink:'https://www.linkedin.com/in/devayani-pawar-28289b295/' },
    { name: 'Dipali Marathe', imgSrc:dip,designation: 'Web Developer | Graphic Designer', delay:'0.6s' },
  ];

  return (
    <div>

<div className="container-fluid py-5 bg-header">
    <video className="background-video" autoPlay muted loop>
      <source src={avideo} type="video/mp4" />
   
    </video>
    <div className="overlay">
      <div className="row py-5">
        <div className="col-12 pt-lg-5 mt-lg-5 text-center">
          <h1 className="display-4 text-white animated zoomIn">About Us</h1>
          <Link to="/" className="h5 text-white">Home</Link>
          <i className="far fa-circle text-white px-2"></i>
          <Link to="/about" className="h5 text-white">About</Link>
        </div>
      </div>
    </div>
  </div>


    <div className="modal fade" id="searchModal" tabIndex="-1" aria-labelledby="searchModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content" style={{ background: 'rgba(9, 30, 62, .7)' }}>
          <div className="modal-header border-0">
            <button type="button" className="btn bg-white btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body d-flex align-items-center justify-content-center">
            <div className="input-group" style={{ maxWidth: '600px' }}>
              <input
                type="text"
                className="form-control bg-transparent border-primary p-3"
                placeholder="Type search keyword"
              />
              <button className="btn btn-primary px-4">
                <i className="bi bi-search"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      </div>
         <div className="modal fade" id="searchModal" tabindex="-1" aria-labelledby="searchModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content" style={{ background: 'rgba(9, 30, 62, .7)' }}>
          <div className="modal-header border-0">
            <button type="button" className="btn bg-white btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body d-flex align-items-center justify-content-center">
            <div className="input-group" style={{ maxWidth: '600px' }}>
              <input
                type="text"
                className="form-control bg-transparent border-primary p-3"
                placeholder="Type search keyword"
              />
              <button className="btn btn-primary px-4">
                <i className="bi bi-search"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-7">
            <div className="section-title position-relative pb-3 mb-5">
              <h5 className="fw-bold text-primary text-uppercase">About Us</h5>
              <h1 className="mb-0">The Best IT Solution With 2 Years of Experience</h1>
            </div>
            <p className="mb-4">
            Based in Jalgaon and founded in 2022, Shree Digital Marketing Agency specializes in 360-degree digital marketing, providing tailored solutions to help brands scale up.

Our expertise extends to content creation, ensuring impactful and engaging material for your audience. At Shree, we are dedicated to transparency, integrity, and delivering measurable results, making us your trusted partner in the dynamic world of digital marketing.

Whether you're a startup looking to establish your online presence or an established brand seeking to expand your reach, we have the strategies and solutions to help you achieve your goals.

Our team of experienced professionals is committed to understanding your unique needs and developing customized strategies to drive growth and success for your business.

Partner with Shree Digital Marketing Agency today and let us help you unlock the full potential of your online presence.
            </p>
            <div className="row g-0 mb-3">
              <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Award Winning</h5>
                <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Professional Staff</h5>
              </div>
              <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>24/7 Support</h5>
                <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Fair Prices</h5>
              </div>
            </div>
            <div className="d-flex align-items-center mb-4 wow fadeIn" data-wow-delay="0.6s">
              <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{ width: '60px', height: '60px' }}>
                <i className="fa fa-phone-alt text-white"></i>
              </div>
              <div className="ps-4">
                <h5 className="mb-2">Call to ask any question</h5>
                <h4 className="text-primary mb-0">+91 7410726107</h4>
              </div>
            </div>
            <a href="quote.html" className="btn btn-primary py-3 px-5 mt-3 wow zoomIn" data-wow-delay="0.9s">Request A Quote</a>
          </div>
          <div className="col-lg-5" style={{ minHeight: '500px' }}>
            <div className="position-relative h-100">
              <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src="assets/img/about.jpg" alt="About Us" style={{ objectFit: 'cover' }} />
            </div>
          </div>
        </div>
      </div>
    </div>

    <section id="testimonials" className="testimonials">
  <div className="container">
    <div className="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
      <div className="swiper-wrapper">
        <div className="swiper-slide">
          <div className="testimonial-item">
            <img src={image} className="test" alt="" />
            <h3>Devendra Waykole</h3>
            <h4>Founder</h4>
            <p>
              <i className="bx bxs-quote-alt-left quote-icon-left"></i>
              Devendra Waykole is the visionary founder behind Shree Digital Marketing Agency. With over a decade of experience in digital marketing and a deep understanding of the industry, he has established himself as a thought leader in the field. His expertise spans SEO, PPC, content marketing, and social media management, which he leverages to drive innovation and deliver exceptional results for clients.
              <i className="bx bxs-quote-alt-right quote-icon-right"></i>
            </p>
          </div>
        </div>
      </div>
      <div className="swiper-pagination"></div>
    </div>
  </div>
</section>


    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container py-5">
        <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: '600px' }}>
          <h5 className="fw-bold text-primary text-uppercase">Team Members</h5>
          <h1 className="mb-0">Professional Stuffs Ready to Help Your Business</h1>
        </div>
        <div className="row g-1"> {/* Adjusted gap between columns */}
          {teamMembers.map((member, index) => (
            <div className="col-lg-6 col-md-12 wow slideInUp" data-wow-delay={member.delay} key={index} style={{ maxWidth: '400px', margin: '0 auto' }}>
              <div className="team-item bg-light rounded overflow-hidden">
                <div className="team-img position-relative overflow-hidden">
                  <img
                    className="img-fluid w-100"
                    src={member.imgSrc}
                    alt={member.name}
                  />
                  <div className="team-social">
                    <a className="btn btn-lg btn-primary btn-lg-square rounded" href={member.flink}><i className="fab fa-facebook-f fw-normal"></i></a>
                    <a className="btn btn-lg btn-primary btn-lg-square rounded" href={member.ilink}><i className="fab fa-instagram fw-normal"></i></a>
                    <a className="btn btn-lg btn-primary btn-lg-square rounded" href={member.llink}><i className="fab fa-linkedin-in fw-normal"></i></a>
                  </div>
                </div>
                <div className="text-center py-4">
                  <h4 className="text-primary">{member.name}</h4>
                  <p className="text-uppercase m-0">{member.designation}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  
    </div>
  )
}

export default About