import React, { useState } from 'react';

function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Construct the WhatsApp message
    const whatsappMessage = `
      Name: ${name}
      Email: ${email}
      Subject: ${subject}
      Message: ${message}
    `;

    // Encode the message for URL
    const encodedMessage = encodeURIComponent(whatsappMessage);
    const whatsappUrl = `https://wa.me/7410726107?text=${encodedMessage}`;

    // Redirect to WhatsApp
    window.open(whatsappUrl, '_blank');
  };
  return (
    <div>
        <div className="modal fade" id="searchModal" tabIndex="-1">
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content" style={{ background: 'rgba(9, 30, 62, .7)' }}>
          <div className="modal-header border-0">
            <button type="button" className="btn bg-white btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body d-flex align-items-center justify-content-center">
            <div className="input-group" style={{ maxWidth: '600px' }}>
              <input type="text" className="form-control bg-transparent border-primary p-3" placeholder="Type search keyword" />
              <button className="btn btn-primary px-4"><i className="bi bi-search"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>

                      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container py-5">
        <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: '600px' }}>
          <h5 className="fw-bold text-primary text-uppercase">Contact Us</h5>
          <h1 className="mb-0">If You Have Any Query, Feel Free To Contact Us</h1>
        </div>
        <div className="row g-5 mb-5">
          {/* Contact Info */}
          <div className="col-lg-4">
            <div className="d-flex align-items-center wow fadeIn" data-wow-delay="0.1s">
              <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{ width: '60px', height: '60px' }}>
                <i className="fa fa-phone-alt text-white"></i>
              </div>
              <div className="ps-4">
                <h5 className="mb-2">Call to ask any question</h5>
                <h4 className="text-primary mb-0">+91 7410726107</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="d-flex align-items-center wow fadeIn" data-wow-delay="0.4s">
              <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{ width: '60px', height: '60px' }}>
                <i className="fa fa-envelope-open text-white"></i>
              </div>
              <div className="ps-4">
                <h5 className="mb-2">Email to get free quote</h5>
                <p className="text-primary mb-0" >buisness@shreedigimarketing.in</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="d-flex align-items-center wow fadeIn" data-wow-delay="0.8s">
              <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{ width: '60px', height: '60px' }}>
                <i className="fa fa-map-marker-alt text-white"></i>
              </div>
              <div className="ps-4">
                <h5 className="mb-2">Visit our office</h5>
                <p className="text-primary mb-0"> Hari House, Shirsoli Rd, Nehru Nagar, Tambapura, Jalgaon, Maharashtra 425001</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-5">
          <div className="col-lg-6 wow slideInUp" data-wow-delay="0.3s">
          <form onSubmit={handleSubmit}>
      <div className="row g-3">
        <div className="col-md-6">
          <input
            type="text"
            className="form-control border-0 bg-light px-4"
            placeholder="Your Name"
            style={{ height: '55px' }}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="col-md-6">
          <input
            type="email"
            className="form-control border-0 bg-light px-4"
            placeholder="Your Email"
            style={{ height: '55px' }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="col-12">
          <input
            type="text"
            className="form-control border-0 bg-light px-4"
            placeholder="Subject"
            style={{ height: '55px' }}
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </div>
        <div className="col-12">
          <textarea
            className="form-control border-0 bg-light px-4 py-3"
            rows="4"
            placeholder="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>
        <div className="col-12">
          <button className="btn btn-primary w-100 py-3" type="submit">Send Message</button>
        </div>
      </div>
    </form>
          </div>
          <div className="col-lg-6 wow slideInUp" data-wow-delay="0.6s">
          <iframe
          className="position-relative rounded w-100 h-140"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14900.784477132687!2d75.5622856!3d20.9847742!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd90f0c2bd251ff%3A0xf023041452b063d!2sShree%20Digital%20Marketing%20Agency!5e0!3m2!1sen!2sin!4v1722923520892!5m2!1sen!2sin"
           width="600"
           height="450" 
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
    </div>

    </div>
  )
}

export default Contact