import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import logo from '../src/asset/shree.png';

function Header() {
  const navbarToggleRef = useRef(null);

  const handleNavLinkClick = () => {
    if (navbarToggleRef.current) {
      navbarToggleRef.current.click(); // Trigger click to collapse menu
    }
  };

  return (
    <div>
      {/* Top Bar */}
      <div className="container-fluid bg-dark px-5 d-none d-lg-block">
        <div className="row gx-0">
          <div className="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
            <div className="d-inline-flex align-items-center" style={{ height: '45px' }}>
              <small className="me-3 text-light">
                <i className="fa fa-map-marker-alt me-2"></i>Hari House,Near Shivraje Washing Center,Neharu nagar,Jalgaon
              </small>
              <small className="me-3 text-light">
                <i className="fa fa-phone-alt me-2"></i>91 7410 726 107
              </small>
              <small className="text-light">
                <i className="fa fa-envelope-open me-2"></i>buisness@shreedigimarketing.in
              </small>
            </div>
          </div>
          <div className="col-lg-4 text-center text-lg-end">
            <div className="d-inline-flex align-items-center" style={{ height: '45px' }}>
              <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href="https://wa.link/xgzo1l">
                <i className="fab fa-twitter fw-normal"></i>
              </a>
              <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href="https://www.facebook.com/people/Shree-Digital-Marketing-Agency/100089689839853/">
                <i className="fab fa-facebook-f fw-normal"></i>
              </a>
              <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href="https://www.linkedin.com/in/devendra-waykole-b0554b219/?originalSubdomain=in">
                <i className="fab fa-linkedin-in fw-normal"></i>
              </a>
              <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href="https://www.instagram.com/shreedigitalmarketings/">
                <i className="fab fa-instagram fw-normal"></i>
              </a>
              <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle" href="https://wa.link/xgzo1l">
                <i className="fab fa-youtube fw-normal"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Navbar */}
      <div className="container-fluid position-relative p-0">
        <nav className="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
          <Link to="/" className="navbar-brand p-0">
            <h1 className="m-0" style={{ color: 'white' }}>
              <img src={logo} alt="Shree Logo" className='logo' style={{ width: '80px', height: '80px' }} />
              <span className='navhead'> Digital Marketing Agency</span>
            </h1>
          </Link>
          <button
            ref={navbarToggleRef}
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="fa fa-bars"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto py-0">
              <Link to="/" className="nav-item nav-link" onClick={handleNavLinkClick}>
                Home
              </Link>
              <Link to="/about" className="nav-item nav-link" onClick={handleNavLinkClick}>
                About
              </Link>
              <Link to="/services" className="nav-item nav-link" onClick={handleNavLinkClick}>
                Services
              </Link>
              <Link to="/prices" className="nav-item nav-link" onClick={handleNavLinkClick}>
                Prices
              </Link>
              <Link to="/contact" className="nav-item nav-link" onClick={handleNavLinkClick}>
                Contact
              </Link>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Header;
